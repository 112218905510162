<template>
    <div class="view">
        <van-nav-bar title="专家信息填报邀请"/>
        <div class="content">
            <div class="inviteMemo" v-html="inviteMemo"/>
            <div class="lk">外语学习与研究出版社</div>
            <div class="btn">
                <van-button type="info" block :to="'/exp/inviteForm?u='+inParams.uuid">开始填报</van-button>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import {Col, Row, Icon, Loading, NavBar, Button} from 'vant';

  export default {
    components: {
      [Col.name]: Col,
      [Row.name]: Row,
      [Icon.name]: Icon,
      [Loading.name]: Loading,
      [NavBar.name]: NavBar,
      [Button.name]: Button,
    },
    data() {
      return {
        inviteMemo: '',
        inParams: {
          uuid: null,
        },
      };
    },
    computed: {
      validEntry() {
        if (!this.inParams.uuid) {
          return false;
        }
        return true;
      },
    },
    methods: {
      goVisitor() {
        this.$router.push({path: '/wxapp/visitor', query: this.inParams});
      },
      initInvite() {
        let that = this;
        that.request({
          params: {method: 'post', uuid: that.inParams.uuid},
          url: '/getInviteMemo',
          callback(code, data) {
            console.log(code, data);
            that.inviteMemo = data.data;
          },
          error() {
          },
        });
      },
      ...mapActions(['request']),
    },
    created() {
      this.inParams.uuid = this.$route.query.u;
    },
    mounted() {
      let that = this;
      that.initInvite();
    },
  };
</script>
<style lang="less" scoped>
    @import '../../../styles/core.less';

    /deep/ .van-uploader__mask {
        border-top-left-radius: 2px;
    }

    .inviteMemo {
        text-align: left;
        font-size: 16px;
        margin: 50px 30px;
        line-height: 180%;
    }

    .lk {
        margin: 0px 30px;
        font-size: 16px;
        text-align: right;
    }

    .btn {
        margin: 30px;
    }

    /*https://zhuanlan.zhihu.com/p/187571392*/
    .inviteMemo {
        /deep/ p {
            text-indent: 2em;
        }

        /deep/ a {
            color: blue;
        }

        /deep/ span {
            margin: 0 5px;
            text-decoration: underline;
        }
    }
</style>
